import React from 'react'
import Link from "gatsby-link";

const Section = ({ path, title, description, date, image }) => {
    return (
        <section className="js-section">
            <img className="image" src={image} alt={title} />
            <h2 className="section__title"><Link to={path}>{title}</Link></h2>
            <small>{date}</small>
            <p>{description}</p>
        </section>
    )
}

export default Section
